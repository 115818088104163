


































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import CepingEdit from "../../components/jiankangceping/ceping-edit.vue";
import { getAssessDetail, addAssessReport } from "@/request/custom";
import { GetFollowupDetail } from "@/request/doctor";
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);
@Component({
  components: {
    MainHeader,
    NavLeft,
    CepingEdit,
  },
})
export default class Name extends Vue {
  private leavePath: any = "";
  private leaveType: any = "下一步";
  private refreshDataTime: any = "";
  private id: any = "";
  private data: any = {};
  private curTemplate: any = {};
  private detail: any = [];
  private ifShowSaveConfirm: any = false;
  private noSaveText: any = "不保存";
  private changeData: any = {};
  private routerPath: any = "";
  private disabledDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private changeTem(item: any) {
    if (this.ifCompose) {
      this.changeData = item;
      this.ifShowSaveConfirm = true;
      this.leaveType = "切换";
    } else {
      this.changeTemSure(item);
    }
  }
  private chilChangeTem(item: any) {
    this.leaveType = "";
    this.curTemplate = item;
    this.getDetail();
  }
  private changeTemSure(item: any) {
    this.curTemplate = item;
    // 刷新子组件数据
    this.refreshDataTime = new Date().getTime();
  }
  private refresh(e: any) {
    this.getDetail();
  }
  private goReport() {
    // 量表完成才能跳转下一步
    let ifFinished: any = true;
    this.data["量表"].forEach((ele: any) => {
      if (!ele.ifSave) {
        ifFinished = false;
      }
    });
    if (ifFinished) {
      if (this.ifCompose) {
        this.leaveType = "下一步";
        this.ifShowSaveConfirm = true;
      } else {
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const params: any = {
          patient_id: this.patient.patient_id,
          template_ids: this.data.template_ids,
          assess_group_id: this.data.assess_group_id,
          assess_id: this.data.assess_id,
        };
        addAssessReport(this, params, loading)
          .then((res: any) => {
            loading.close();
            this.$router.push({
              path: "/main/workstation/patient-pinggu-report",
              query: {
                id: res,
              },
            });
          })
          .catch(() => {
            loading.close();
          });
      }
    } else {
      this.$message.warning("请先完成评估内容!");
      return;
    }
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        followup_id: this.data.followup_id,
      },
    };
    GetFollowupDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
        if (this.curTemplate && !this.curTemplate.template_id) {
          // 不存在取第一个
          this.changeTemSure(res.templates[0]);
        } else {
          // 存在需要更新数据
          res["templates"].forEach((ele: any) => {
            if (this.curTemplate["template_id"] == ele["template_id"]) {
              this.changeTemSure(ele);
            }
          });
        }
        // 根据是否有内容id,来生成是否保存状态
        // this.data["量表"].forEach((ele: any) => {
        //   if (ele.content_id || ele.consultation_id || ele.inspect_id) {
        //     ele.ifSave = true;
        //   } else {
        //     ele.ifSave = false;
        //   }
        // });
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    this.routerPath = to.fullPath;
    if (this.ifCompose) {
      this.leavePath = to.path;
      this.ifShowSaveConfirm = true;
      this.leaveType = "离开";
      next(false);
    } else {
      next(true);
    }
  }
  private mounted() {
    if (this.$route.query.id) {
      this.data.followup_id = this.$route.query.id;
      this.getDetail();
    }
  }
}
